import Link from 'next/link';
import { useTheme } from '@/contexts/ThemeContext';
import { checkIfSubscriptionTheme } from '@/utils/helpers';
import { useEffect, useState } from 'react';

export default function Footer(): JSX.Element {
  const theme = useTheme();
  const IS_BANK = process.env.NEXT_PUBLIC_IS_BANK === 'true';
  const HOSTNAME = process.env.NEXT_PUBLIC_HOSTNAME;
  const isSubscriptionTheme = checkIfSubscriptionTheme(
    theme.slug,
    theme.landingPage
  );
  const [termsLink, setTermsLink] = useState('');
  const [privacyLink, setPrivacyLink] = useState('');
  const [contactMail, setContactMail] = useState('');

  useEffect(() => {
    if (IS_BANK) {
      const domain = window.location.origin.replace('apply.', '');
      setTermsLink(`${domain}/terms-and-conditions`);
      setPrivacyLink(`${domain}/privacy-policy`);
      setContactMail(domain);
    }
    if (theme?.slug?.includes('dl.')) {
      const domain = theme.slug.replace('dl.', '');
      setTermsLink(`https://${domain}/terms`);
      setPrivacyLink(`https://${domain}/privacy`);
      setContactMail(domain);
    }
  }, [theme.slug]);

  const conditionalRenderFooterMessage = () => {
    if (theme.landingPage === 'v2') {
      return (
        <p className="font-medium leading-6 text-gray-900 text-center">
          We are a third party independent company, we are not affiliated with
          the{' '}
          <a
            className="underline"
            href="https://www.gov.uk/browse/driving/driving-licences"
            target="_blank"
            rel="noreferrer"
          >
            DVLA
          </a>{' '}
          or the UK government in any way. We charge a service fee for
          additional benefits.
        </p>
      );
    } else if (isSubscriptionTheme) {
      return (
        <p className={`font-medium leading-6 text-gray-900 text-center`}>
          We are in no way affiliated with the UK Government. We are a third
          party agency which charges a service fee of £89 every 3 months with a
          3-day free trial. You do not need to use our service to apply for a UK
          driving licence. You may instead apply for one directly via the UK
          Government website at{' '}
          <a
            className="underline"
            href="https://www.gov.uk"
            target="_blank"
            rel="noreferrer"
          >
            www.gov.uk
          </a>
          , for which they may or may not charge a fee.
        </p>
      );
    } else {
      return (
        <p className={`font-medium leading-6 text-gray-900 text-center`}>
          This non-official website is an optional checking service with no
          governmental or&nbsp;
          <a
            className="underline"
            href="https://www.gov.uk/browse/driving/driving-licences"
            target="_blank"
            rel="noreferrer"
          >
            DVLA
          </a>
          &nbsp; affiliation. <br />
          Applications can be made directly on&nbsp;
          <a
            className="underline"
            href="https://www.gov.uk"
            target="_blank"
            rel="noreferrer"
          >
            www.gov.uk
          </a>
          &nbsp; without our product and service fee.
        </p>
      );
    }
  };

  return (
    <div
      className={`${
        theme.landingPage === 'v2'
          ? 'bg-gray-100 text-xs'
          : theme.landingPage === 'v3'
          ? ' mx-auto border-t-2 border-gray-900 text-xs'
          : 'bg-gray-100 border-t border-gray-900 text-sm'
      }`}
    >
      <div className="max-w-screen-xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div>{conditionalRenderFooterMessage()}</div>
        <div className="mt-5">
          {isSubscriptionTheme && (
            <>
              <div className="flex justify-center mb-5">
                <img
                  className="px-2"
                  src="/images/visa-logo.png"
                  alt="Visa Logo"
                />
                <img
                  className="px-2"
                  src="/images/mastercard-logo.png"
                  alt="Mastercard Logo"
                />
                <img
                  className="px-2"
                  src="/images/maestro-logo.png"
                  alt="Maestro Logo"
                />
              </div>
            </>
          )}
          <ul
            className={`flex items-center justify-center md:flex-row pb-2 ${
              theme.landingPage === 'v2'
                ? 'text-xs flex-col'
                : theme.landingPage === 'v3'
                ? 'flex-row'
                : 'text-sm flex-col'
            }`}
          >
            <li className={`${theme.landingPage === 'v3' ? 'mx-1' : 'mx-4'}`}>
              {termsLink ? (
                <a
                  href={termsLink}
                  target={'_blank'}
                  className="leading-6 text-gray-900 hover:text-gray-900 hover:underline"
                  rel={'noreferrer'}
                >
                  Terms and Conditions
                </a>
              ) : (
                <Link href="/terms-and-conditions">
                  <a className="leading-6 text-gray-900 hover:text-gray-900 hover:underline">
                    Terms and Conditions
                  </a>
                </Link>
              )}
            </li>
            {theme.landingPage === 'v3' && <span>-</span>}
            <li className={`${theme.landingPage === 'v3' ? 'mx-1' : 'mx-4'}`}>
              {privacyLink ? (
                <a
                  href={privacyLink}
                  target={'_blank'}
                  className="leading-6 text-gray-900 hover:text-gray-900 hover:underline"
                  rel={'noreferrer'}
                >
                  Privacy Policy
                </a>
              ) : (
                <Link href="/privacy-policy">
                  <a className="leading-6 text-gray-900 hover:text-gray-900 hover:underline">
                    Privacy Policy
                  </a>
                </Link>
              )}
            </li>
            {theme.landingPage !== 'v3' && (
              <li className="mx-4">
                <a
                  href={
                    contactMail
                      ? `mailto:contact@${contactMail}`
                      : `mailto:contact@${HOSTNAME}`
                  }
                  className="leading-6 text-gray-900 hover:text-gray-900 hover:underline"
                >
                  Contact Us
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
